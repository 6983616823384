import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {AuthenticationService} from '../../../services/authentication.service';
import {ApplicationConfig} from 'frontend/src/app/classes/application-config';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {takeUntilDestroyed, toSignal} from '@angular/core/rxjs-interop';
import {map} from 'rxjs/operators';

@Component({
    selector: 'eaglei-login-form',
    templateUrl: './login-form.component.html',
    styleUrls: ['./login-form.component.scss'],
})
export class LoginFormComponent {
    public formGroup: FormGroup = new FormGroup({
        username: new FormControl<string>('', [Validators.required]),
        password: new FormControl<string>('', [Validators.required]),
    });

    public errorCode: number;
    public showPassword: boolean;

    protected emailOrUsername = toSignal<string>(
        this.formGroup.controls.username.valueChanges.pipe(
            map((value: string) => {
                if (value?.includes('@')) return 'email';
                return 'username';
            })
        )
    );

    constructor(private router: Router, private auth: AuthenticationService) {
        this.formGroup.valueChanges.pipe(takeUntilDestroyed()).subscribe(() => (this.errorCode = undefined));
    }

    /**
     * Tries to login the user with their username and password.
     * If the username and password are valid the user is redirected to the lite map on mobile and landing page otherwise. If the username
     * and password are not valid, an error message is shown.
     */
    public login(): void {
        const username = this.formGroup.controls['username'].value;
        const password = this.formGroup.controls['password'].value;

        this.auth.login(username || ' ', password).subscribe({
            next: (valid: any) => {
                if (valid.status === 200) {
                    if (ApplicationConfig.onMobile()) {
                        // noinspection JSIgnoredPromiseFromCall
                        this.router.navigateByUrl(
                            '/app/lite/map',
                            /* Removed unsupported properties by Angular migration: preserveQueryParams. */ {}
                        );
                    } else {
                        // noinspection JSIgnoredPromiseFromCall

                        this.router.navigateByUrl(
                            '/app/landing',
                            /* Removed unsupported properties by Angular migration: preserveQueryParams. */ {}
                        );
                    }
                } else {
                    this.errorCode = valid.status;
                }
            },
            error: (error?: any) => {
                this.errorCode = error.status;
            },
        });
    }

    goToReactivationPage() {
        const state = {};
        state[this.emailOrUsername()] = this.formGroup.controls.username.value;

        // noinspection JSIgnoredPromiseFromCall
        this.router.navigate(['login/account-reactivation'], {state});
    }
}
