import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {AbstractControl, AsyncValidatorFn, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Router} from '@angular/router';
import {of} from 'rxjs';
import {map} from 'rxjs/operators';
import {AuthenticationService} from '../../../services/authentication.service';
import {User} from 'frontend/src/app/modules/user/classes/user';

@Component({
    selector: 'eaglei-change-password',
    templateUrl: './change-password.component.html',
    /*template: `
        <div class="new-design">
            <div class="login-form">
                <p fxFlex="initial" class="success-text">
                    <fa-icon icon="check"></fa-icon>
                    A Temporary password has been sent to your email, Please log in and reset your password from your profile page.
                </p>
            </div>
        </div>
    `,*/
    styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {
    // HTML elements
    @ViewChild('helpPanel') helpPanel: ElementRef<HTMLDivElement>;
    @ViewChild('passwordField', {static: true}) passwordField: ElementRef<HTMLDivElement>;

    public formGroup: UntypedFormGroup;
    public isAuthenticated = false;
    public isLoading = true;

    constructor(private auth: AuthenticationService, private router: Router, private popup: MatSnackBar) {
        const controls = {
            password: new UntypedFormControl('', {
                updateOn: 'blur',
                validators: [Validators.required],
                asyncValidators: [this.passwordComplexityValidator()],
            }),
            confirm: new UntypedFormControl('', {
                updateOn: 'change',
                validators: [this.passwordsMatch(), Validators.required],
            }),
        };

        this.formGroup = new UntypedFormGroup(controls);
    }

    ngOnInit() {
        this.auth.authenticatedUser.subscribe((user: User | undefined) => {
            if (user) {
                this.isAuthenticated = true;
            }
            this.isLoading = false;
        });
    }

    // API Methods
    /**
     * Changes the password for the user that has the token in the url. If it fails an erros is displayed to the user, if it is
     * successful, it will navigate back to the login page.
     */
    public changePassword(): void {
        if (this.formGroup.invalid) {
            return;
        }
        const password = this.formGroup.controls['password'].value;
        this.auth.changePassword(password).subscribe(
            (success) => {
                if (success) {
                    this.popup.open('Your password was changed. You will be redirected to the login page.', '', {
                        panelClass: 'success',
                        duration: 2500,
                    });

                    window.setTimeout(() => this.router.navigate(['/login']), 2500);
                } else {
                    this.errorPopup();
                }
            },
            (error) => {
                this.errorPopup();
                console.error(error);
            }
        );
    }

    /**
     * Opens the error popup with the correct message.
     */
    private errorPopup() {
        this.popup
            .open(`There was an error changing your password. \n If the problem persists, email the admin for help`, 'Email', {
                panelClass: ['failure', 'popup-text'],
            })
            .onAction()
            .subscribe(() => {
                const subject = encodeURI(`Password Reset Error For ${this.auth.authenticatedUser.value.username}`);
                window.location.href = `mailto:eagle-i@ornl.gov?subject=${subject}`;
            });
    }

    // Validator Methods
    /**
     * Checks to see if the current value in the password fields passed the application complexity requirements
     */
    private passwordComplexityValidator(): AsyncValidatorFn {
        return (control: AbstractControl) => {
            if (this.formGroup) {
                this.formGroup.controls.confirm.updateValueAndValidity();
            }

            if (control.value) {
                return this.auth.checkPasswordComplexity(control.value).pipe(map((fault) => (fault ? {complexity: fault} : null)));
            }
            return of(null);
        };
    }

    // noinspection DuplicatedCode
    /**
     * Checks to see if the password matches the confirm_password field
     */
    private passwordsMatch(): ValidatorFn {
        return (control: AbstractControl) => {
            const password = this.formGroup ? this.formGroup.controls['password'].value : undefined;
            if (!password) {
                return null;
            }

            if (control.value !== undefined) {
                return control.value === password ? null : {match: 'New passwords do not match'};
            }
            return null;
        };
    }

    // Helper Methods
    /**
     * navigates back to the login page
     */
    public goBack(): void {
        // noinspection JSIgnoredPromiseFromCall
        this.router.navigateByUrl('/login');
    }
}
