import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {MatMenuTrigger} from '@angular/material/menu';
import {MatSnackBar} from '@angular/material/snack-bar';
import {distinctUntilChanged, distinctUntilKeyChanged, filter, map, switchMap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {GenRoleDefinition} from '../../../../generated/serverModels/GenRoleDefinition';
import {AuthenticationService} from '../../services/authentication.service';
import {User} from '../../modules/user/classes/user';
import {RequestApiKeyModalComponent} from '../../modules/api-key/modals/request-api-key-modal/request-api-key-modal.component';
import {ApiKeyService} from '../../modules/api-key/services/api-key.service';
import {BulkEmailComponent} from '../../../shared/modals/bulk-email/bulk-email.component';
import {ModalConfig} from '../../classes/modal-config';
import {ApplicationConfig} from '../../classes/application-config';
import {AppEnvironment} from '../../enums/environment.enum';

interface MenuList {
    access: () => boolean;
    url: string;
    set: string;
    icon: string;
    name: string;
}

@Component({
    selector: 'eaglei-account-dropdown',
    templateUrl: './account-dropdown.component.html',
    styleUrls: ['./account-dropdown.component.scss'],
})
export class AccountDropdownComponent implements OnInit {
    @Input() showAsList: boolean = false;

    get isApiAccessible(): boolean {
        return this._isApiAccessible;
    }

    get isSystemNewsReporter(): boolean {
        return this._isSystemNewsReporter;
    }

    get isContentManager(): boolean {
        return this._isContentManager;
    }

    get isUserManager(): boolean {
        return this._isUserManager;
    }

    get isAdmin(): boolean {
        return this._isAdmin;
    }

    get isOutageAdmin(): boolean {
        return this._isOutageAdmin;
    }

    public user: User;
    public canLockTime$ = this.auth.authenticatedUser.pipe(
        distinctUntilChanged(),
        filter((user) => !!user),
        map((user: User) => {
            const env = (window as any).eaglei.env;
            return (
                user.hasRole(GenRoleDefinition.ROLE_ADMIN) &&
                [AppEnvironment.TRAINING, AppEnvironment.TEST, AppEnvironment.DEVELOPMENT].includes(env)
            );
        })
    );

    // Management Menu Elements
    public readonly managementMenuItems: MenuList[] = [
        {
            access: () => this.isUserManager && !ApplicationConfig.useMobileLayout(),
            url: '/app/user/manage',
            set: 'fas',
            icon: 'user-plus',
            name: 'User Management',
        },
        {
            access: () => this.isContentManager && !ApplicationConfig.useMobileLayout(),
            url: '/app/layers/manage',
            set: 'far',
            icon: 'map',
            name: 'Layer Management',
        },
        {
            access: () => this.isContentManager && !ApplicationConfig.useMobileLayout(),
            url: '/app/reports/manage',
            set: 'fas',
            icon: 'chart-line',
            name: 'Report Management',
        },
        {
            access: () => this.isApiAccessible && !ApplicationConfig.useMobileLayout(),
            url: '/app/apikey/manage',
            set: 'fas',
            icon: 'key',
            name: 'API-Key Management',
        },
        {
            access: () => this.isOutageAdmin && !ApplicationConfig.useMobileLayout(),
            url: '/app/management/subutility',
            set: 'fas',
            icon: 'industry',
            name: 'SubUtility Management',
        },
    ];

    // Permission properties

    private _isAdmin = false;
    private _isOutageAdmin: boolean;
    private _isApiAccessible: boolean;
    private _isContentManager: boolean;
    private _isUserManager: boolean;
    private _isSystemNewsReporter: boolean;
    private _isEilManager: boolean;

    private _managementAccess: boolean;

    public username = '';
    public isAuthenticated = false;
    public isImpersonating = false;

    private baseHtmlElement: HTMLElement;

    public showManagementMenu: boolean;

    @ViewChild('lockTrigger') trigger: MatMenuTrigger;

    constructor(
        private router: Router,
        private dialog: MatDialog,
        private popup: MatSnackBar,
        private apiKeyService: ApiKeyService,
        public auth: AuthenticationService
    ) {}

    ngOnInit() {
        const self = this;
        this.auth.authenticatedUser.subscribe((user: User | undefined) => {
            if (user) {
                self.user = user;
                self.username = user.username;
                self.isAuthenticated = true;
                self.isImpersonating = user.impersonating;

                self._isAdmin = user.hasRole(GenRoleDefinition.ROLE_ADMIN);
                self._isOutageAdmin = user.hasRole(GenRoleDefinition.ROLE_OUTAGE_ADMIN);
                self._isUserManager = user.hasRole(GenRoleDefinition.ROLE_USER_MANAGER);
                self._isApiAccessible = user.hasRole(GenRoleDefinition.ROLE_USER_MANAGER);
                self._isContentManager = user.hasRole(GenRoleDefinition.ROLE_CONTENT_MANAGER);
                self._isSystemNewsReporter = user.hasRole(GenRoleDefinition.ROLE_SYSTEM_NEWS_REPORTER);
                self._isEilManager = user.hasRole(GenRoleDefinition.ROLE_EIL_MANAGER);

                self._managementAccess = self.isAdmin || self.isOutageAdmin || self.isContentManager || self.isUserManager;
            } else {
                self.username = '';
                self._isAdmin = false;
                self.isAuthenticated = false;
                self.isImpersonating = false;
            }
        });

        this.baseHtmlElement = document.getElementsByTagName('html')[0];
    }

    /**
     * Un-impersonates a user and returns back to the admin user profile.
     */
    onUnimpersonate(): void {
        const self = this;
        this.auth.unimpersonate().subscribe(() => self.router.navigateByUrl('/app/landing'));
    }

    /**
     * Logs out the current user
     */
    onLogout(): void {
        this.auth.logout();
    }

    /**
     * Opens the bulk email modal for system-wide notifications
     */
    public sendEmail(): void {
        const config: MatDialogConfig = {
            width: ModalConfig.getModalWidth(),
        };
        this.dialog.open(BulkEmailComponent, config);
    }

    /**
     * Triggers the api key request modal to open
     */
    public openApiRequestModal(): void {
        this.apiKeyService.getApiKey(this.user).subscribe(() => {
            const userId: number = (this.auth.authenticatedUser.getValue() as User).id;

            const responseHandler = {
                next: () => {
                    this.popup.open('API-Key Request Sent', '', {duration: 1000, panelClass: ['success']});
                    this.user.pendingAPIKeyRequest = true;
                },
                error: (error) => {
                    this.popup.open('API-Key Request Failed', '', {duration: 1000, panelClass: ['failure']});
                    throw error;
                },
            };

            this.dialog
                .open(RequestApiKeyModalComponent, ModalConfig.defaultConfig)
                .afterClosed()
                .pipe(
                    filter((value) => !!value),
                    switchMap((request) => {
                        request.userId = userId;
                        return this.apiKeyService.requestApiKey(request);
                    })
                )
                .subscribe(responseHandler);
        });
    }

    // Helper Methods
    public showManagementLinks(): boolean {
        return this.managementMenuItems.some((link) => link.access());
    }

    public useMobileLayout(checkPhone: boolean = false): boolean {
        if (checkPhone) {
            return ApplicationConfig.onPhone();
        }
        return ApplicationConfig.onMobile();
    }
}
