import {Component, inject} from '@angular/core';
import {AuthenticationService} from '../../../services/authentication.service';
import {ActivatedRoute, Router} from '@angular/router';
import {FormControl, UntypedFormControl, Validators} from '@angular/forms';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

@Component({
    selector: 'eaglei-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent {
    private authenticationService = inject(AuthenticationService);
    private router = inject(Router);
    private route = inject(ActivatedRoute);

    protected responseCode: number;
    protected pendingRequest: boolean;

    protected nameControl = new FormControl<string>('', {
        validators: [Validators.required],
    });

    constructor() {
        this.route.params.pipe(takeUntilDestroyed()).subscribe((params) => {
            this.nameControl.setValue(params['username']);
        });
    }

    /**
     * Tries to send the request for a password change email.
     * if the user is disabled it will prompt to email the admin.
     * if the username or email is invalid, it will show an error to the user.
     */
    public requestPasswordReset(): void {
        this.responseCode = undefined;
        this.pendingRequest = true;
        this.authenticationService.requestPasswordReset(this.nameControl.value).subscribe({
            next: () => {
                this.responseCode = 200;
                setTimeout(() => this.goBack(), 3_000);
            },
            error: (error: any) => {
                this.responseCode = error.status;
                this.pendingRequest = false;
            },
        });
    }

    /**
     * navigates back to the login page
     */
    public goBack(): void {
        // noinspection JSIgnoredPromiseFromCall
        this.router.navigateByUrl('/login');
    }

    public goToReactivation(): void {
        const state = {};
        state[this.nameControl.value.includes('@') ? 'email' : 'username'] = this.nameControl.value;

        // noinspection JSIgnoredPromiseFromCall
        this.router.navigate(['login/account-reactivation'], {state});
    }
}
