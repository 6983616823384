import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot, Resolve} from '@angular/router';
import {DataService} from '../services/data.service';

@Injectable({
    providedIn: 'root',
})
export class GeneratedOutagesDataGuard implements Resolve<any> {
    constructor(private dataService: DataService) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        // This only returns true because the map function on the api call sets up all the data needed,
        // so if the call succeeds it can just return true to tell the route guard to load.
        return this.dataService
            .getGeneratedOutages()
            .toPromise()
            .then(() => true);
    }
}
