import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginLayoutComponent} from './components/login-layout/login-layout.component';
import {LoginFormComponent} from './components/login-form/login-form.component';
import {VerifyEmailComponent} from './components/verify-email/verify-email.component';
import {ResetPasswordComponent} from './components/reset-password/reset-password.component';
import {ChangePasswordComponent} from './components/change-password/change-password.component';
import {AccountRequestComponent} from './components/account-request/account-request.component';
import {AccountReactivationComponent} from './components/account-reactivation/account-reactivation.component';

export const loginRoutes: Routes = [
    {
        path: '',
        component: LoginLayoutComponent,
        data: {
            useNewDesign: true,
        },
        children: [
            {
                path: '',
                component: LoginFormComponent,
            },
            {
                path: 'reset-password',
                component: ResetPasswordComponent,
            },
            {
                path: 'change-password/:code',
                component: ChangePasswordComponent,
            },
            {
                path: 'verify-email/:code',
                component: VerifyEmailComponent,
            },
            {
                path: 'account-request',
                component: AccountRequestComponent,
            },
            {
                path: 'account-reactivation',
                component: AccountReactivationComponent,
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(loginRoutes)],
    exports: [RouterModule],
})
export class LoginRoutingModule {}
