import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {loginRoutes} from './login/login-routing.module';
import {mapRoutes} from './modules/map/map-routing.module';
import {AuthenticatedUserGuard} from './guards/authenticated-user.guard';
import {BasePageComponent} from './components/base-page/base-page.component';
import {TermsOfUseComponent} from './components/terms-of-use/terms-of-use.component';
import {ResolveUserGuard} from './guards/resolve-user.guard';
import {LocationNameGuard} from './guards/location-name.guard';
import {layerRoutes} from './modules/layer/layer-routing.module';
import {OverrideDataGuard} from './guards/override-data.guard';
import {RoleGuard} from './guards/role.guard';
import {GenRoleDefinition} from '../../generated/serverModels/GenRoleDefinition';
import {ResolutionCheckComponent} from './components/resolution-check/resolution-check.component';
import {ReportGuard} from './guards/report.guard';
import {StopTimeGuard} from './guards/stop-time.guard';
import {GeneratedOutagesDataGuard} from './guards/generated-outages-data.guard';
import {AppEnvironment} from './enums/environment.enum';

const routes: Routes = [
    {
        path: '',
        resolve: {
            locations: LocationNameGuard,
        },
        children: [
            {
                path: 'login',
                data: {
                    useNewDesign: true,
                },
                children: [...loginRoutes],
            },
            {
                path: 'app',
                canActivate: [AuthenticatedUserGuard],
                component: BasePageComponent,
                resolve: {
                    user: ResolveUserGuard,
                    overrideData: OverrideDataGuard,
                    generatedOutages: GeneratedOutagesDataGuard,
                    stopTime: StopTimeGuard,
                },
                data: {title: (window as any).eaglei.env === AppEnvironment.TRAINING ? 'SPARROW-ME' : 'EAGLE-I™'},
                children: [
                    {
                        path: 'resolution',
                        canActivate: [RoleGuard],
                        component: ResolutionCheckComponent,
                        data: {
                            requiredRoles: [GenRoleDefinition.ROLE_ADMIN],
                        },
                    },
                    {
                        path: 'landing',
                        loadChildren: () => import('./landing/landing.module').then((m) => m.LandingModule),
                        data: {title: 'Dashboard'},
                    },
                    {
                        path: 'training',
                        loadChildren: () => import('./modules/training/training.module').then((m) => m.TrainingModule),
                        data: {title: 'Training'},
                    },
                    {
                        path: 'events',
                        loadChildren: () => import('./modules/recent-events/recent-events.module').then((m) => m.RecentEventsModule),
                        data: {title: 'Events'},
                    },
                    {
                        path: 'system-event',
                        loadChildren: () => import('./modules/system-event/system-event.module').then((m) => m.SystemEventModule),
                        data: {title: 'System Events Dashboard'},
                    },
                    {
                        path: 'systemnews',
                        loadChildren: () => import('./modules/system-news/system-news.module').then((m) => m.SystemNewsModule),
                        data: {title: 'System News'},
                    },
                    {
                        path: 'help',
                        loadChildren: () => import('./modules/help/help.module').then((m) => m.HelpModule),
                        data: {title: 'Help'},
                    },
                    {
                        resolve: {
                            report: ReportGuard,
                        },
                        path: 'reports',
                        loadChildren: () => import('./modules/report/report.module').then((m) => m.ReportModule),
                        data: {title: 'Reports'},
                    },
                    {
                        path: 'apikey',
                        loadChildren: () => import('./modules/api-key/api-key.module').then((m) => m.ApiKeyModule),
                        data: {title: 'Api Key'},
                    },
                    {
                        path: 'user',
                        loadChildren: () => import('./modules/user/user.module').then((m) => m.UserModule),
                        data: {title: 'User'},
                    },
                    {
                        path: 'alerts',
                        loadChildren: () => import('./modules/alert/alert.module').then((m) => m.AlertModule),
                        data: {title: 'Alerts'},
                    },
                    {
                        path: 'map',
                        children: [...mapRoutes],
                        data: {title: 'Mapper', useNewDesign: true},
                    },
                    {
                        path: 'lite',
                        loadChildren: () => import('./modules/lite/lite.module').then((m) => m.LiteModule),
                        data: {title: 'Mapper'},
                    },
                    {
                        path: 'layers',
                        children: [...layerRoutes],
                        data: {title: 'Layer'},
                    },
                    {
                        path: 'communities',
                        loadChildren: () => import('./modules/community/community.module').then((m) => m.CommunityModule),
                        data: {title: 'Community', useNewDesign: true},
                    },
                    {
                        path: 'management',
                        loadChildren: () => import('./modules/management/management.module').then((m) => m.ManagementModule),
                        data: {title: 'Management'},
                    },
                ],
            },
            {
                path: '',
                redirectTo: '/app/landing', // user guard will send to login if not authenticated
                pathMatch: 'full',
            },
        ],
    },
    {
        path: 'attribution',
        loadChildren: () => import('./modules/attribution/attribution.module').then((m) => m.AttributionModule),
        data: {title: 'Attribution'},
    },
    {
        path: 'termsOfUse',
        component: TermsOfUseComponent,
    },
    {
        path: '**',
        redirectTo: '/app/landing',
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            scrollPositionRestoration: 'top',
            onSameUrlNavigation: 'reload',
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
