<div class="appContainer">
    <div id="content" class="container widthLimiter">
        <div class="row main">
            <div class="column">
                <h1>EAGLE-I™ Terms of Use</h1>
            </div>
        </div>
        <div class="row">
            <p></p>
            <p></p>

            <p>
                This Terms of Use (TOU) agreement is for access to and use of the U.S. Department of Energy Environment for Analysis of
                Geo-Located Energy Information (EAGLE-I™) System.
            </p>
            <h4>I certify that:</h4>
            <ul>
                <li>
                    I am either a U.S. Department of Energy (DOE) employee, Federal employee, a contractor supporting a Federal employee, or
                    a state emergency energy assurance coordinator.
                </li>
                <li>
                    My role and purpose for access to or use of the EAGLE-I™ System is to support the federal emergency support function as
                    a DOE or federal mission partner.
                </li>
            </ul>
            <p>
                In addition to the above certification, I agree to the terms and conditions below. Noncompliance with these terms will
                result in EAGLE-I™ account termination.
            </p>
            <h4><span class="header-underline">Terms and Conditions</span></h4>
            <ul>
                <li>
                    Information on the EAGLE-I™ website is for official use only. I will protect all information from unauthorized use,
                    access, or disclosure.
                </li>
                <li>
                    I will protect my user ID and password and not share with anyone. If I suspect my password has been compromised, I will
                    initiate the process to generate a new password.
                </li>
                <li>
                    I understand that this website is part of a Federal computer system used to accomplish Federal functions. The Department
                    of Energy monitors this website for security purposes to ensure it remains available to all users and to protect
                    information in the system. By accessing this website, I am expressly consenting to these monitoring activities.
                </li>
                <li>
                    I will not attempt to defeat or circumvent security features; use the system for other than intended purposes; access,
                    obtain, alter, damage, or destroy information; or otherwise interfere with the system or its operation.
                </li>
                <li>
                    I agree that EAGLE-I™ will store non-sensitive personally identifiable information (PII) about me, my sponsor, and my
                    point of contact (i.e., work email, work address, work phone number). I understand that this information is necessary to
                    grant access to EAGLE-I™ and will only be seen by EAGLE-I™ administrators and DOE community managers.
                </li>
                <li>
                    I understand that this website contains links to many websites created and maintained by other public and/or private
                    organizations. By clicking these links, I am subject to the privacy and security policies of the owners/sponsors of the
                    outside website(s).
                </li>
            </ul>

            <p>&nbsp;</p>
            <p>EAGLE-I™ Terms of Use, version 11/20/2017</p>
        </div>
    </div>
</div>
