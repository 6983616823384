<button mat-menu-item [mat-menu-trigger-for]="lockTimeMenu" #lockTrigger="matMenuTrigger">
    <fa-icon icon="lock"></fa-icon>
    <span>Lock Time</span>
</button>

<button mat-menu-item (click)="unlockTime()">
    <fa-icon icon="unlock"></fa-icon>
    <span>Unlock Time</span>
</button>

<mat-menu #lockTimeMenu="matMenu" [class]="'stop-time-panel'">
    <div (click)="$event.stopPropagation()">
        <eaglei-date-range
            [singlePicker]="true"
            [showApplyButton]="true"
            [showTime]="true"
            [newDesign]="true"
            [layout]="'column'"
            [buttonText]="'Lock Time'"
            [min]="minDate"
            (change)="lockTime($event)"
            [allowFutureEndDate]="isTraining"
            [start]="selectedDate">
        </eaglei-date-range>
    </div>
</mat-menu>
