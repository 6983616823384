import {Component, OnInit} from '@angular/core';
import {ApplicationConfig} from '../../classes/application-config';

@Component({
    selector: 'eaglei-resolution-check',
    templateUrl: './resolution-check.component.html',
    styleUrls: ['./resolution-check.component.scss'],
})
export class ResolutionCheckComponent implements OnInit {
    constructor() {}

    ngOnInit() {}

    onMobile() {
        return ApplicationConfig.onMobile();
    }

    onIosMobile() {
        return ApplicationConfig.onIosMobile();
    }

    onPhone() {
        return ApplicationConfig.onPhone();
    }

    getLayout() {
        const v = ApplicationConfig.layoutType.getValue().valueOf();
        switch (v) {
            case 0:
                return 'desktop';
            case 1:
                return 'mobile';
            case 2:
                return 'phone';
        }
    }
}
