import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {forkJoin} from 'rxjs';
import {DataService} from '../services/data.service';
import {FemaRegion} from '../modules/outage/classes/fema-region';
import {State} from '../modules/outage/classes/state';

@Injectable({
    providedIn: 'root',
})
export class LocationNameGuard implements Resolve<any> {
    constructor(private dataService: DataService) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        const femaRegions = this.dataService.getFemaNames();
        const states = this.dataService.getStateNames();

        const stateCall = this.dataService.getStateGeometries();
        const countyCall = this.dataService.getCountyGeometries();

        return forkJoin([femaRegions, states, stateCall, countyCall])
            .toPromise()
            .then((res) => {
                const sortedRegions = res[0].sort((a: FemaRegion, b: FemaRegion) => {
                    if (a.id > b.id) {
                        return 1;
                    } else if (a.id < b.id) {
                        return -1;
                    }
                    return 0;
                });

                const sortedStates = res[1].sort((a: State, b: State) => {
                    if (a.abbreviation > b.abbreviation) {
                        return 1;
                    } else if (a.abbreviation < b.abbreviation) {
                        return -1;
                    }
                    return 0;
                });

                // Do this async as not to mess with load times
                setTimeout(() => {
                    const statesByFema = new Map<number, State[]>();
                    res[1].forEach((s) => {
                        const val = statesByFema.get(s.dotregion) || [];
                        val.push(s);
                        statesByFema.set(s.dotregion, val);
                    });
                    DataService.statesByFema.next(statesByFema);

                    const responseRegions = res[0];
                    const responseStates = res[1];

                    this.dataService.stateOptions = responseStates.map((s) => {
                        return {
                            state: s,
                            selected: false,
                        };
                    });

                    this.dataService.regionOptions = responseRegions.map((region) => {
                        const regionStates = responseStates
                            .filter((s) => s.dotregion === region.id)
                            .map((rs) => {
                                return {
                                    state: rs,
                                    selected: false,
                                };
                            });
                        return {
                            regionId: region.id,
                            states: regionStates,
                            selected: false,
                        };
                    });
                }, 0);

                this.dataService.stateNames.next(sortedStates);
                this.dataService.femaNames.next(sortedRegions);
                DataService.states.next(sortedStates);
                DataService.femaRegions.next(sortedRegions);
                return true;
            });
    }
}
