<div class="new-design">
    <div class="page-title">
        <button mat-icon-button (click)="goBack()">
            <mat-icon>arrow_back</mat-icon>
        </button>
        <span>Change Password</span>
    </div>

    <div *ngIf="isAuthenticated && !isLoading">
        <div class="request-form">
            <form [formGroup]="formGroup" autocomplete="off">
                <mat-form-field appearance="outline" subscriptSizing="fixed">
                    <mat-label>Password</mat-label>
                    <input type="password" matInput autofocus formControlName="password" (keyup.enter)="changePassword()" />
                    <mat-hint
                        >Password requires: 8 characters or more, At least one number, At least one special character, At least one upper
                        and lower-case character</mat-hint
                    >
                    <mat-error>
                        <ng-container *ngIf="formGroup.controls['password'].errors">
                            <span *ngIf="formGroup.controls['password'].errors['required']">Password Required</span>
                            <span *ngIf="formGroup.controls['password'].errors['complexity']">
                                {{ formGroup.controls['password'].errors['complexity'] }}
                            </span>
                        </ng-container>
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" subscriptSizing="fixed">
                    <mat-label>Confirm Password</mat-label>
                    <input type="password" name="confirm" matInput formControlName="confirm" (keyup.enter)="changePassword()" />

                    <mat-error>
                        <ng-container *ngIf="formGroup.controls['confirm'].errors">
                            <span *ngIf="formGroup.controls['confirm'].errors['required']">Required</span>
                            <span *ngIf="formGroup.controls['confirm'].errors['match']">
                                {{ formGroup.controls['confirm'].errors['match'] }}
                            </span>
                        </ng-container>
                    </mat-error>
                </mat-form-field>
            </form>
        </div>

        <div class="actions">
            <button mat-flat-button color="primary" class="wide" [disabled]="formGroup.invalid" (click)="changePassword()">
                Change Password
            </button>
        </div>
    </div>

    <div *ngIf="!isAuthenticated && !isLoading">
        <p>
            Cannot validate password reset link. Please request a new link or
            <a href="mailto:eagle-i@ornl.gov?subject=Password reset link not validating">contact us</a> if you continue to experience this
            issue.
        </p>
    </div>
</div>
