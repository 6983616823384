<div class="new-design">
    <header>EAGLE-I&trade;</header>

    <div class="page-title">
        <div>
            <button mat-icon-button (click)="goBack()">
                <mat-icon>arrow_back</mat-icon>
            </button>
            <span>Sign Up</span>
        </div>
        <br />
        <a class="reason-info" tabindex="0" (keyup.enter)="openReasonInfo()" (keyup.space)="openReasonInfo()" (click)="openReasonInfo()">
            Am I eligible for an account?
        </a>
    </div>

    <div class="request-form">
        <mat-stepper [orientation]="(onMobile$ | async) ? 'vertical' : 'horizontal'">
            <mat-step [stepControl]="personalFormGroup">
                <ng-template matStepLabel>Personal Information</ng-template>
                <ng-container [ngTemplateOutlet]="personalInfo"></ng-container>
                <div class="action-buttons single-button">
                    <button
                        mat-flat-button
                        color="primary"
                        [disabled]="personalFormGroup.invalid || personalFormGroup.pending"
                        matStepperNext>
                        Next
                    </button>
                </div>
            </mat-step>

            <mat-step [stepControl]="pocFormGroup" *ngIf="pocNeeded">
                <ng-template matStepLabel>Federal Point of Contact</ng-template>
                <ng-container [ngTemplateOutlet]="pocInfo"></ng-container>
                <div class="action-buttons">
                    <button mat-stroked-button matStepperPrevious>Back</button>
                    <button mat-flat-button color="primary" [disabled]="pocFormGroup.invalid || pocFormGroup.pending" matStepperNext>
                        Next
                    </button>
                </div>
            </mat-step>

            <mat-step [stepControl]="accountFormGroup">
                <ng-template matStepLabel>Account Info</ng-template>
                <ng-container [ngTemplateOutlet]="accountInfo"></ng-container>
                <div class="action-buttons">
                    <button mat-stroked-button matStepperPrevious>Back</button>
                    <button
                        mat-raised-button
                        color="primary"
                        [disabled]="accountFormGroup.invalid || accountFormGroup.pending"
                        (click)="requestAccount()">
                        Submit
                    </button>
                </div>
            </mat-step>
        </mat-stepper>
    </div>
</div>

<ng-template #personalInfo>
    <form class="personal-form" [formGroup]="personalFormGroup">
        <!--Name -->
        <div class="input-row">
            <mat-form-field class="half-width" subscriptSizing="fixed">
                <mat-label>First Name</mat-label>
                <input type="text" matInput formControlName="firstName" />
                <mat-error>
                    <ng-container *ngIf="personalFormGroup.controls.firstName.hasError('required')">
                        <span>First name is required</span>
                    </ng-container>
                    <ng-container *ngIf="personalFormGroup.controls.firstName.hasError('pattern')">
                        <span>First name must consist of more than a space.</span>
                    </ng-container>
                </mat-error>
            </mat-form-field>

            <mat-form-field class="half-width" subscriptSizing="fixed">
                <mat-label>Last Name</mat-label>
                <input type="text" matInput formControlName="lastName" />
                <mat-error>
                    <ng-container *ngIf="personalFormGroup.controls.lastName.hasError('required')">
                        <span>Last name is required</span>
                    </ng-container>
                    <ng-container *ngIf="personalFormGroup.controls.lastName.hasError('pattern')">
                        <span>Last name must consist of more than a space.</span>
                    </ng-container>
                </mat-error>
            </mat-form-field>
        </div>

        <!--Job info-->
        <div class="input-row">
            <mat-form-field class="half-width" subscriptSizing="fixed">
                <mat-label>Job Title</mat-label>
                <input type="text" matInput formControlName="title" />
                <mat-error>
                    <ng-container *ngIf="personalFormGroup.controls.title.hasError('required')">
                        <span>Job title is required</span>
                    </ng-container>
                    <ng-container *ngIf="personalFormGroup.controls.title.hasError('pattern')">
                        <span>Job title must consist of more than a space.</span>
                    </ng-container>
                </mat-error>
            </mat-form-field>

            <mat-form-field class="half-width" subscriptSizing="fixed">
                <mat-label>Department</mat-label>
                <input type="text" matInput formControlName="department" />
                <mat-error>
                    <ng-container *ngIf="personalFormGroup.controls.department.hasError('required')">
                        <span>Department is required</span>
                    </ng-container>
                    <ng-container *ngIf="personalFormGroup.controls.department.hasError('pattern')">
                        <span>Department must consist of more than a space.</span>
                    </ng-container>
                </mat-error>
            </mat-form-field>
        </div>

        <!--Organization-->
        <div class="input-row">
            <mat-form-field class="wide" subscriptSizing="fixed">
                <mat-label>Organization</mat-label>
                <input type="text" matInput formControlName="organization" />
                <mat-error>
                    <ng-container *ngIf="personalFormGroup.controls.organization.hasError('required')">
                        <span>Organization is required</span>
                    </ng-container>
                    <ng-container *ngIf="personalFormGroup.controls.organization.hasError('pattern')">
                        <span>Organization must consist of more than a space.</span>
                    </ng-container>
                </mat-error>
            </mat-form-field>
        </div>

        <!--Location-->
        <div class="input-row">
            <mat-form-field class="half-width" subscriptSizing="fixed">
                <mat-label>City</mat-label>
                <input type="text" matInput formControlName="city" />
                <mat-error>
                    <ng-container *ngIf="personalFormGroup.controls.city.hasError('required')">
                        <span>City is required</span>
                    </ng-container>
                    <ng-container *ngIf="personalFormGroup.controls.city.hasError('pattern')">
                        <span>City must consist of more than a space.</span>
                    </ng-container>
                </mat-error>
            </mat-form-field>

            <div class="half-width state-zip-container">
                <mat-form-field class="half-width" subscriptSizing="fixed">
                    <mat-label>State</mat-label>
                    <mat-select formControlName="state">
                        <mat-option *ngFor="let state of states" [value]="state.abbreviation">{{ state.name }}</mat-option>
                    </mat-select>
                    <mat-error>State is required</mat-error>
                </mat-form-field>

                <mat-form-field class="half-width" subscriptSizing="fixed">
                    <mat-label>Postal Code</mat-label>
                    <input type="text" matInput eagleiNumberInput formControlName="postalCode" />
                    <mat-error>
                        <ng-container *ngIf="personalFormGroup.controls.postalCode.hasError('format')">
                            <span>{{ personalFormGroup.controls.postalCode.errors['format'] }}</span>
                        </ng-container>

                        <ng-container *ngIf="personalFormGroup.controls.postalCode.hasError('required')">
                            <span>Postal code is required.</span>
                        </ng-container>

                        <ng-container *ngIf="personalFormGroup.controls.postalCode.hasError('pattern')">
                            <span>Postal code must consist of more than a space.</span>
                        </ng-container>
                    </mat-error>
                </mat-form-field>
            </div>
        </div>

        <!--Email-->
        <div class="input-row">
            <mat-form-field class="half-width" subscriptSizing="fixed">
                <mat-label>Work Email</mat-label>
                <input type="text" matInput formControlName="email" (blur)="checkPocNeeded()" />
                <mat-error>
                    <span *ngIf="personalFormGroup.controls.email.hasError('required')"> An email is required </span>
                    <span *ngIf="personalFormGroup.controls.email.hasError('email')">
                        Email is not in proper format (ex your.name&#64;example.com)
                    </span>
                    <span *ngIf="personalFormGroup.controls.email.hasError('unique')">
                        {{ personalFormGroup.controls.email.errors['unique'] }}
                    </span>

                    <ng-container *ngIf="personalFormGroup.controls.email.hasError('pattern')">
                        <span>Email must consist of more than a space.</span>
                    </ng-container>
                </mat-error>
            </mat-form-field>

            <mat-form-field class="half-width" subscriptSizing="fixed">
                <mat-label>Confirm Work Email</mat-label>
                <input type="email" matInput formControlName="confirmEmail" />
                <mat-error>
                    <ng-container *ngIf="personalFormGroup.controls['confirmEmail'].errors">
                        <span *ngIf="personalFormGroup.controls['confirmEmail'].hasError('required')">
                            Email Confirmation is required
                        </span>
                        <span *ngIf="personalFormGroup.controls['confirmEmail'].errors['match']">
                            {{ personalFormGroup.controls['confirmEmail'].errors['match'] }}
                        </span>

                        <ng-container *ngIf="personalFormGroup.controls['confirmEmail'].hasError('pattern')">
                            <span>Confirmation must consist of more than a space.</span>
                        </ng-container>
                    </ng-container>
                </mat-error>
            </mat-form-field>
        </div>

        <!--Phone-->
        <div class="input-row">
            <mat-form-field class="half-width" subscriptSizing="fixed">
                <mat-label>Work Phone Number</mat-label>
                <input type="tel" matInput eagleiNumberInput [allowDecimal]="false" formControlName="telephone" />
                <mat-error>
                    <span *ngIf="personalFormGroup.controls.telephone.hasError('required')"> Phone number is required </span>

                    <span *ngIf="personalFormGroup.controls.telephone.hasError('length')">
                        {{ personalFormGroup.controls.telephone.errors['length'] }}
                    </span>

                    <ng-container *ngIf="personalFormGroup.controls.telephone.hasError('pattern')">
                        <span>Phone number must consist of more than a space.</span>
                    </ng-container>
                </mat-error>
            </mat-form-field>
        </div>
    </form>
</ng-template>

<ng-template #pocInfo>
    <form class="poc-form" [formGroup]="pocFormGroup">
        <!--Name and Organization -->
        <div class="input-row">
            <mat-form-field class="half-width" subscriptSizing="fixed">
                <mat-label>Name</mat-label>
                <input type="text" matInput formControlName="name" />
                <mat-error>
                    <ng-container *ngIf="pocFormGroup.controls.name.hasError('required')">
                        <span>POC name is required</span>
                    </ng-container>
                    <ng-container *ngIf="pocFormGroup.controls.name.hasError('pattern')">
                        <span>POC name must consist of more than a space.</span>
                    </ng-container>
                </mat-error>
            </mat-form-field>

            <mat-form-field class="half-width" subscriptSizing="fixed">
                <mat-label>Organization</mat-label>
                <input type="text" matInput formControlName="organization" />
                <mat-error>
                    <ng-container *ngIf="pocFormGroup.controls.organization.hasError('required')">
                        <span>POC organization is required</span>
                    </ng-container>
                    <ng-container *ngIf="pocFormGroup.controls.organization.hasError('pattern')">
                        <span>POC organization must consist of more than a space.</span>
                    </ng-container>
                </mat-error>
            </mat-form-field>
        </div>

        <!--Job info-->
        <div class="input-row">
            <mat-form-field class="half-width" subscriptSizing="fixed">
                <mat-label>Department</mat-label>
                <input type="text" matInput formControlName="department" />
                <mat-error>
                    <ng-container *ngIf="pocFormGroup.controls.department.hasError('required')">
                        <span>POC department is required</span>
                    </ng-container>
                    <ng-container *ngIf="pocFormGroup.controls.department.hasError('pattern')">
                        <span>POC department must consist of more than a space.</span>
                    </ng-container>
                </mat-error>
            </mat-form-field>

            <mat-form-field class="half-width" subscriptSizing="fixed">
                <mat-label>Job Title</mat-label>
                <input type="text" matInput formControlName="title" />
                <mat-error>
                    <ng-container *ngIf="pocFormGroup.controls.title.hasError('required')">
                        <span>POC job title is required</span>
                    </ng-container>
                    <ng-container *ngIf="pocFormGroup.controls.title.hasError('pattern')">
                        <span>POC job title must consist of more than a space.</span>
                    </ng-container>
                </mat-error>
            </mat-form-field>
        </div>

        <!--Contact-->
        <div class="input-row">
            <mat-form-field class="half-width" subscriptSizing="fixed">
                <mat-label>Work Email</mat-label>
                <input type="text" matInput formControlName="email" />
                <mat-error>
                    <span *ngIf="pocFormGroup.controls.email.hasError('required')"> An email is required </span>
                    <span *ngIf="pocFormGroup.controls.email.hasError('email')">
                        Email is not in proper format (ex your.name&#64;example.com)
                    </span>

                    <ng-container *ngIf="pocFormGroup.controls.email.hasError('pattern')">
                        <span>POC email must consist of more than a space.</span>
                    </ng-container>
                </mat-error>
            </mat-form-field>

            <mat-form-field class="half-width" subscriptSizing="fixed">
                <mat-label>Work Phone Number</mat-label>
                <input type="tel" matInput eagleiNumberInput [allowDecimal]="true" formControlName="telephone" />
                <mat-error>
                    <span *ngIf="pocFormGroup.controls.telephone.hasError('required')"> A phone number is required </span>

                    <span *ngIf="pocFormGroup.controls.telephone.hasError('length')">
                        {{ pocFormGroup.controls.telephone.errors['length'] }}
                    </span>

                    <ng-container *ngIf="pocFormGroup.controls.telephone.hasError('pattern')">
                        <span>POC phone number must consist of more than a space.</span>
                    </ng-container>
                </mat-error>
            </mat-form-field>
        </div>
    </form>
</ng-template>

<ng-template #accountInfo>
    <form class="account-form" [formGroup]="accountFormGroup" autocomplete="off">
        <div class="user-fields-block">
            <mat-form-field subscriptSizing="fixed">
                <mat-label>Username</mat-label>
                <input type="text" matInput formControlName="username" />
                <mat-error>
                    <span *ngIf="accountFormGroup.controls.username.hasError('required')"> A username is required </span>

                    <span *ngIf="accountFormGroup.controls.username.hasError('complexity')">
                        {{ accountFormGroup.controls.username.errors['complexity'] }}
                    </span>

                    <span *ngIf="accountFormGroup.controls.username.hasError('unique')">
                        {{ accountFormGroup.controls.username.errors['unique'] }}
                    </span>

                    <ng-container *ngIf="accountFormGroup.controls.username.hasError('pattern')">
                        <span>Username must consist of more than a space.</span>
                    </ng-container>
                </mat-error>
            </mat-form-field>

            <mat-form-field subscriptSizing="fixed">
                <mat-label>Password</mat-label>
                <input [type]="showPassword ? 'text' : 'password'" matInput formControlName="password" autocomplete="new-password" />
                <mat-icon matSuffix (click)="showPassword = !showPassword">
                    {{ showPassword ? 'visibility_off' : 'visibility' }}
                </mat-icon>
                <mat-error>
                    <span *ngIf="accountFormGroup.controls.password.hasError('required')"> A password is required </span>

                    <span *ngIf="accountFormGroup.controls.password.hasError('complexity')">
                        {{ accountFormGroup.controls.password.errors['complexity'] }}
                    </span>
                </mat-error>
            </mat-form-field>

            <mat-form-field subscriptSizing="fixed">
                <mat-label>Confirm Password</mat-label>
                <input [type]="showConfirmPassword ? 'text' : 'password'" matInput formControlName="confirm" autocomplete="new-password" />
                <mat-icon matSuffix (click)="showConfirmPassword = !showConfirmPassword">
                    {{ showConfirmPassword ? 'visibility_off' : 'visibility' }}
                </mat-icon>
                <mat-error>
                    <span *ngIf="accountFormGroup.controls.confirm.hasError('required')"> A password is required </span>

                    <span *ngIf="accountFormGroup.controls.confirm.hasError('match')">
                        {{ accountFormGroup.controls.confirm.errors['match'] }}
                    </span>
                </mat-error>
            </mat-form-field>

            <mat-checkbox formControlName="termsOfUse">
                I have reviewed and agreed to the <a routerLink="/termsOfUse" target="_blank">Terms of Use</a>.
            </mat-checkbox>
        </div>

        <div class="reason-block">
            <mat-form-field subscriptSizing="fixed">
                <mat-label>Reason</mat-label>
                <textarea type="text" matInput formControlName="reason" cdkTextareaAutosize cdkAutosizeMinRows="5" cdkAutosizeMaxRows="5">
                </textarea>
                <mat-hint>Reason for account must include connection with ESF Community.</mat-hint>
                <mat-error>A reason is required</mat-error>
            </mat-form-field>
        </div>
    </form>
</ng-template>

<ng-template #reasonInfo>
    <div cdkFocusTrap>
        <div class="dialog-header">
            <button cdkFocusInitial mat-icon-button (click)="reasonDailogRef?.close()">
                <fa-icon icon="times"></fa-icon>
            </button>
        </div>
        <mat-dialog-content class="reason-dialog-content">
            <p>
                <b>SLTT Accounts:</b> To obtain approval for a state or local account, an account request must contain reasonable
                justification with a direct connection to emergency response and the energy sector, a validated state or local government
                email address, and hold a relevant appointed office or have a strong affiliation with a state or local government.
            </p>
            <p>
                <b>Federal Accounts:</b> To obtain approval for a federal account, an account request must contain reasonable justification
                including connection to emergency response and the energy sector or related research needs, a validated government email
                address, and a close affiliation with the US federal government.
            </p>
        </mat-dialog-content>
    </div>
</ng-template>
