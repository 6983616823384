import {Pipe, PipeTransform} from '@angular/core';
import {GenNotificationType} from '../../../generated/serverModels/GenNotificationType';

@Pipe({
    name: 'notificationType',
})
export class NotificationTypePipe implements PipeTransform {
    transform(value: GenNotificationType, args?: any): string {
        switch (value) {
            case GenNotificationType.COMMUNITY:
                return 'COMMUNITY REQUEST';
            case GenNotificationType.SYSTEM_NEWS:
                return 'SYSTEM NEWS';
            case GenNotificationType.RECENT_EVENT:
                return 'RECENT_EVENT';
            case GenNotificationType.PROFILE:
                return 'PROFILE';
            case GenNotificationType.PASSWORD:
                return 'PASSWORD EXPIRING SOON';
            case GenNotificationType.API_KEY:
                return 'API KEY EXPIRING SOON';

            default:
                return value.name;
        }
    }
}
