<ul>
    <li>
        On Mobile - <b>{{ onMobile() }}</b>
    </li>
    <li>
        On Phone - <b>{{ onPhone() }}</b>
    </li>
    <li>
        On ISO Mobile - <b>{{ onIosMobile() }}</b>
    </li>
    <li>
        Layout To Use (based on resolution) - <b>{{ getLayout() }}</b>
    </li>
</ul>
