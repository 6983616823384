<div class="new-design">
    <div class="page-title">
        <button mat-icon-button (click)="goBack()">
            <mat-icon>arrow_back</mat-icon>
        </button>
        <span>Account Reactivation</span>
    </div>
    <div class="request-form">
        <form [formGroup]="infoGroup">
            <mat-form-field appearance="outline" subscriptSizing="fixed">
                <mat-label>Username</mat-label>
                <input type="text" matInput formControlName="username" (blur)="checkUsernameEmailMatching()" />
                <mat-error>
                    <ng-container *ngIf="infoGroup.controls.username.hasError('required')"> A username is required</ng-container>

                    <ng-container *ngIf="infoGroup.controls.username.hasError('complexity')">
                        {{ infoGroup.controls.username.errors['complexity'] }}
                    </ng-container>

                    <ng-container *ngIf="infoGroup.controls.username.hasError('unique')">
                        {{ infoGroup.controls.username.errors['unique'] }}
                    </ng-container>

                    <ng-container *ngIf="infoGroup.controls.username.hasError('pattern')">
                        <span>Username must consist of more than a space.</span>
                    </ng-container>
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Email</mat-label>
                <input type="email" matInput formControlName="email" (blur)="checkUsernameEmailMatching()" />
                <mat-error>
                    <ng-container *ngIf="infoGroup.controls.email.hasError('required')"> An email is required</ng-container>
                    <ng-container *ngIf="infoGroup.controls.email.hasError('email')">
                        Email is not in proper format (ex your.name&#64;example.com)
                    </ng-container>
                    <ng-container *ngIf="infoGroup.controls.email.hasError('unique')">
                        {{ infoGroup.controls.email.errors['unique'] }}
                    </ng-container>
                    <ng-container *ngIf="infoGroup.controls.email.hasError('pattern')">
                        <span>Email must consist of more than a space.</span>
                    </ng-container>
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" subscriptSizing="fixed">
                <mat-label>Reason</mat-label>
                <textarea matInput formControlName="reason" cdkTextareaAutosize cdkAutosizeMinRows="5" cdkAutosizeMaxRows="5"></textarea>
                <mat-error>
                    <ng-container *ngIf="infoGroup.controls.reason.hasError('required')">
                        <span>A reactivation reason is required</span>
                    </ng-container>
                    <ng-container *ngIf="infoGroup.controls.reason.hasError('pattern')">
                        <span>Reason must consist of more than a space.</span>
                    </ng-container>
                </mat-error>
            </mat-form-field>

            <div class="error-text hide" [class.show]="!infoMatches">
                <span>
                    The username and email do not match our records.
                    <br />
                    If you need assistance please <a href="mailto:eagle-i@ornl.gov">contact the admin</a>.
                </span>
            </div>

            <div class="error-text hide" [class.show]="showPendingMessage">
                <span>
                    There is already a pending reactivation request for your account.
                    <br />
                    If you need assistance please <a href="mailto:eagle-i@ornl.gov">contact the admin</a>.
                </span>
            </div>

            <button
                mat-flat-button
                color="primary"
                [disabled]="infoGroup.invalid || !infoMatches || showPendingMessage"
                (click)="submitRequest()">
                Submit
            </button>
        </form>
    </div>
</div>
