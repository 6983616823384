import {Component, OnInit, ViewChild} from '@angular/core';
import {MatMenuTrigger} from '@angular/material/menu';
import moment from 'moment';
import {LockTimeService} from '../../services/lock-time.service';
import {AppEnvironment} from '../../enums/environment.enum';

@Component({
    selector: 'eaglei-stop-time',
    templateUrl: './stop-time.component.html',
    styleUrls: ['./stop-time.component.scss'],
})
export class StopTimeComponent implements OnInit {
    @ViewChild('lockTrigger') menu: MatMenuTrigger;
    public selectedDate = moment();
    public readonly minDate: moment.Moment = moment().subtract(2, 'months');
    public isTraining = [AppEnvironment.TEST, AppEnvironment.TRAINING, AppEnvironment.DEVELOPMENT].includes((window as any)?.eaglei?.env);

    constructor(private lockService: LockTimeService) {}

    ngOnInit() {}

    public unlockTime(): void {
        this.lockService.unlockTime();
    }

    public lockTime(date: moment.Moment): void {
        this.lockService.lockTime(date);
        this.menu.closeMenu();
    }
}
