import {Component, OnDestroy} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import {Subscription} from 'rxjs';

@Component({
    selector: 'eaglei-login-layout',
    templateUrl: 'login-layout.component.html',
    styleUrls: ['login-layout.component.scss'],
})
export class LoginLayoutComponent implements OnDestroy {
    public requestingAccount: boolean = false;
    public showWelcomeText: boolean = false;
    private sub: Subscription;

    constructor(private router: Router) {
        this.sub = this.router.events.pipe(filter((event: any) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
            this.requestingAccount = event.url.includes('account-request');
            this.showWelcomeText = !event.url.includes('account-reactivation');
        });
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }
}
