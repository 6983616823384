import {Component} from '@angular/core';
import {AbstractControl, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators} from '@angular/forms';
import {debounceTime, filter} from 'rxjs/operators';
import {AuthenticationService} from '../../../services/authentication.service';
import {User} from '../../../modules/user/classes/user';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Router} from '@angular/router';

@Component({
    selector: 'eaglei-account-reactivation',
    templateUrl: './account-reactivation.component.html',
    styleUrls: ['./account-reactivation.component.scss'],
})
export class AccountReactivationComponent {
    public infoGroup: UntypedFormGroup;
    public infoMatches: boolean = true;

    private readonly usernamePattern: RegExp = new RegExp('^[A-Z]+[A-Z0-9._]*$', 'i');
    public showPendingMessage: boolean;

    constructor(private authenticationService: AuthenticationService, private popup: MatSnackBar, private router: Router) {
        const username = this.router?.getCurrentNavigation()?.extras?.state?.username;
        const email = this.router?.getCurrentNavigation()?.extras?.state?.email;

        if (!email && !username) {
            console.warn('Navigating back to login because no username or email was supplied');
            this.goBack();
        }

        const textPattern = Validators.pattern(/[.*[\S].*/);
        const controls = {
            username: new UntypedFormControl('', [textPattern, Validators.required, this.validUsername()]),
            email: new UntypedFormControl('', [textPattern, Validators.required, Validators.email]),
            reason: new UntypedFormControl('', [textPattern, Validators.required]),
        };

        this.infoGroup = new UntypedFormGroup(controls);

        if (username) {
            controls.username.setValue(username);
            this.infoGroup.controls.username.disable();
        } else if (email) {
            controls.email.setValue(email);
            this.infoGroup.controls.email.disable();
        }

        this.authenticationService
            .checkForExistingReactivationRequest(username || email)
            .pipe(filter((res) => !!res))
            .subscribe({
                next: () => {
                    this.infoGroup.disable();
                    this.showPendingMessage = true;
                },
                error: (error) => {
                    console.error(error);
                },
            });
    }

    // Validator Methods
    /**
     * checks to see if the username matches the application requirements
     */
    private validUsername(): ValidatorFn {
        return (control: AbstractControl) => {
            if (control.value) {
                return this.usernamePattern.test(control.value) ? null : {complexity: 'Username is invalid'};
            }
            return null;
        };
    }

    public checkUsernameEmailMatching(): void {
        const usernameControl = this.infoGroup.controls.username;
        const emailControl = this.infoGroup.controls.email;

        this.authenticationService
            .checkUsernameEmailMatching(usernameControl.value.toLowerCase(), emailControl.value)
            .pipe(
                // Checking if !errors here because a disabled field will never be valid
                filter(() => !usernameControl.errors && !emailControl.errors),
                debounceTime(250)
            )
            .subscribe((res) => {
                this.infoMatches = res;
            });
    }

    public submitRequest(): void {
        const userInfo = new User();
        userInfo.username = this.infoGroup.controls.username.value;
        userInfo.email = this.infoGroup.controls.email.value;
        userInfo.reason = this.infoGroup.controls.reason.value;

        this.authenticationService.requestReactivation(userInfo).subscribe({
            next: () => {
                this.popup.open('Request Sent', 'Okay', {panelClass: 'dialog-success', duration: 3_000});

                this.authenticationService.navigateToLogin();
            },
            error: () => {
                this.popup.open('Failed to send request', 'Okay', {panelClass: 'dialog-failure', duration: 3_000});
            },
        });
    }

    goBack() {
        // noinspection JSIgnoredPromiseFromCall
        this.router.navigateByUrl('/login');
    }
}
